import React, { useState, useEffect, useRef } from 'react';
import './ChatWindow.css';
import { useAuth } from '../../utils/AuthContext';
import ButtonWithLoading from '../../components/Buttons/GeneralButton/BaseButton';
import ReactMarkdown from 'react-markdown';

const BFCTestChatWindow = ({ baseURL, chatId }) => {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const [isConnected, setIsConnected] = useState(false);
    const messagesEndRef = useRef(null);
    const socketRef = useRef(null);
    const { reauthenticate, sessionToken } = useAuth();
    const [userEngaged, setUserEngaged] = useState(false);
    const [currentChatId, setCurrentChatId] = useState(null);
    const [isStreaming, setIsStreaming] = useState(false);

    useEffect(() => {
        // Initialize WebSocket connection
        socketRef.current = new WebSocket(baseURL?.includes("dev") ? 'wss://dev.tagin.ai/bfc_health_app_chat' : 'wss://prod.tagin.ai/bfc_health_app_chat');

        socketRef.current.onopen = () => {
            console.log("WebSocket connection established.");
            // Send a connection event with the chatId when the WebSocket opens
            socketRef.current.send(JSON.stringify({
                event: 'connected',
                user_session_token: sessionToken,
                chat_id: currentChatId,
            }));
        };

        // Handle incoming messages from the WebSocket server
        socketRef.current.onmessage = async (event) => {
            try {
                const messageContent = JSON.parse(event.data);
                console.log("Received message:", messageContent);

                if (messageContent.event === 'server:message:stream:started') {
                    console.log("Stream started");
                    setIsProcessing(true);
                    setIsStreaming(true);
                    setMessages(prevMessages => [...prevMessages, { text: '', incoming: true, role: 'assistant' }]);
                } else if (messageContent.event === 'server:message:stream:new_chunk') {
                    console.log("New chunk:", messageContent.message);
                    setMessages(prevMessages => {
                        const newMessages = [...prevMessages];
                        const lastMessage = newMessages[newMessages.length - 1];
                        lastMessage.text += messageContent.message;
                        return newMessages;
                    });
                } else if (messageContent.event === 'server:message:stream:ended') {
                    console.log("Stream ended");
                    setIsProcessing(false);
                    setIsStreaming(false);
                    // Only scroll after stream ends
                    if (messagesEndRef.current && userEngaged) {
                        messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
                    }
                } else if (messageContent.event === 'server:message:stream:error') {
                    console.error("Message stream failed");
                    setIsProcessing(false);
                    setIsStreaming(false);
                } else if (messageContent.event === 'server:chat:connected') {
                    setIsConnected(true);
                    setIsProcessing(false);
                } else if (messageContent.event === 'server:chat:created') {
                    setCurrentChatId(messageContent.chat_id);
                    setIsConnected(true);
                    setIsProcessing(false);
                } else if (messageContent.event === 'server:error') {
                    setIsConnected(false);
                    setIsProcessing(false);
                    setIsStreaming(false);

                    if (messageContent.error === "UNAUTHORIZED") {
                        await reauthenticate();
                        if (input.trim()) {
                            const messageData = {
                                event: 'client:message',
                                message: input,
                                chat_id: currentChatId,
                            };
                            socketRef.current.send(JSON.stringify(messageData));
                            setMessages((prevMessages) => {
                                const isMessageAlreadyAdded = prevMessages.some(msg => msg.text === input && !msg.incoming);
                                if (!isMessageAlreadyAdded) {
                                    return [...prevMessages, { text: input, incoming: false, role: 'user' }];
                                }
                                return prevMessages;
                            });
                        }
                    } else {
                        console.error("Error received from server:", messageContent.error);
                    }
                } else if (messageContent.event === 'server:chat:not_available') {
                    console.log("Chat not available");
                    setIsConnected(false);
                    setIsProcessing(false);
                }
            } catch (error) {
                console.error("Error parsing message from server:", error);
                setIsConnected(false);
                setIsProcessing(false);
                setIsStreaming(false);
            }
        };

        socketRef.current.onerror = (error) => {
            console.error("WebSocket error:", error);
            setIsConnected(false);
            setIsProcessing(false);
            setIsStreaming(false);
        };

        socketRef.current.onclose = () => {
            console.log("WebSocket connection closed.");
            setIsConnected(false);
            setIsProcessing(false);
            setIsStreaming(false);
        };

        // Cleanup when the component unmounts
        return () => {
            socketRef.current.close();
        };
    }, [currentChatId]);

    const handleSendMessage = () => {
        if (input.trim() && !isStreaming) {
            setIsProcessing(true);
            const messageData = {
                event: 'client:message',
                message: input,
                chat_id: currentChatId,
            };
            socketRef.current.send(JSON.stringify(messageData));

            setMessages((prevMessages) => [...prevMessages, { text: input, incoming: false, role: 'user' }]);
            setInput('');
            setUserEngaged(true);
        }
    };

    const handleInputChange = (e) => {
        setInput(e.target.value);
        setUserEngaged(true);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !isStreaming) {
            handleSendMessage();
        }
    };

    useEffect(() => {
        // Only scroll on new messages, not during streaming
        if (messagesEndRef.current && userEngaged && !isStreaming) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages, userEngaged]);

    return (
        <div className="chat-window-wrapper">
            <div className="chat-window">
                <div className="chat-messages">
                    {messages.map((message, index) => (
                        <div
                            key={index}
                            className={`chat-bubble ${message.incoming ? 'incoming' : 'outgoing'}`}
                        >
                            <ReactMarkdown components={{
                                p: ({ children }) => <p style={{ margin: 0 }}>{children}</p>
                            }}>{message.text}</ReactMarkdown>
                        </div>
                    ))}
                    {(isProcessing || !isConnected) && !isStreaming && (
                        <div className="chat-bubble incoming typing-indicator">
                            <span className="dot"></span>
                            <span className="dot"></span>
                            <span className="dot"></span>
                        </div>
                    )}
                    <div ref={messagesEndRef} />
                </div>
                <div className="chat-input-container">
                    <input
                        type="text"
                        className="chat-input"
                        placeholder="Type a message..."
                        value={input}
                        onChange={handleInputChange}
                        onKeyPress={handleKeyPress}
                        disabled={isStreaming}
                    />
                    <ButtonWithLoading
                        onClick={handleSendMessage}
                        loading={isProcessing}
                        disabled={!input.trim() || isProcessing || !isConnected || isStreaming}
                    >
                        Send
                    </ButtonWithLoading>
                </div>
            </div>
        </div>
    );
};

export default BFCTestChatWindow;
